import React, {useEffect} from 'react';
import mapboxgl from 'mapbox-gl'
import './Map.css'
import 'mapbox-gl/dist/mapbox-gl.css'

const Map = () => {

  mapboxgl.accessToken = 'pk.eyJ1IjoiZHBsZXR0ZXItZXNpIiwiYSI6ImNrZjFnd3pwazBheTgyc3IxbjNwZXd1a3AifQ.0D6vjydDCLD4lskV7kMM3g';

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/dpletter-esi/ckf1i952t2sf11allsjpg5j05?optimize=true', // stylesheet location
      center: [-121.0208051, 39.261393], // starting position [lng, lat]
      zoom: 9, // starting zoom
      antialias: true
    });

    // const cutoffTime = Date.now() - (1000 * 1000 * 60 * 60); //one hour ago
    // const purpleAir = {
    //   'type': 'FeatureCollection',
    //   features:
    //     purple.results
    //       .filter(item =>
    //         item['Hidden'] === "false"
    //         && item['DEVICE_LOCATIONTYPE'] === 'outside'
    //         && (item['LastSeen'] * 1000) > cutoffTime
    //         && !item['Flag']
    //         && item['AGE'] < 60 //1 hour
    //         && item['PM2_5Value']
    //       )
    //       .map(item => {
    //         return {
    //           type: 'Feature',
    //           geometry: {
    //             type: 'Point',
    //             coordinates: [item['Lon'], item['Lat']]
    //           },
    //           properties: {
    //             label: item['Label'],
    //             t: item['temp_f'],
    //             h: item['humidity'],
    //             p: item['pressure'],
    //             pm25: item['PM2_5Value']
    //           }
    //         }
    //       })
    // };
    //
    // console.log(purpleAir);


    map.on('load', () => {

      // map.addSource('purpleair', {
      //   type: 'geojson',
      //   data: purpleAir
      // });
      //
      // map.addLayer({
      //   'id': 'purpleair-label',
      //   'type': 'symbol',
      //   'source': 'purpleair',
      //   'layout': {
      //     'text-field': ['get', 'pm25']
      //   },
      //   'paint': {
      //     'text-color': '#0000FF',
      //     'text-opacity': 0.7
      //   }
      // });


      map.addSource('wildfire', {
        type: 'geojson',
        data: 'https://opendata.arcgis.com/datasets/5da472c6d27b4b67970acc7b5044c862_0.geojson'
      });

      map.addLayer({
        'id': 'wildfire',
        'type': 'line',
        'source': 'wildfire',
        'paint': {
          'line-color': '#FF0000',
          'line-opacity': 0.8,
          'line-width': 1.0,
          'line-blur': 2.0
        }
      });


      map.addLayer({
        'id': 'wildfire-fill',
        'type': 'fill',
        'source': 'wildfire',
        'paint': {
          'fill-color': '#FF0000',
          'fill-opacity': 0.2
        }
      });

      map.addLayer({
        'id': 'wildfire-label',
        'type': 'symbol',
        'source': 'wildfire',
        'layout': {
          'text-field': ['get', 'IncidentName']
        },
        'paint': {
          'text-color': '#FF0000',
          'text-opacity': 0.7
        }
      });

      map.addLayer({
        'id': 'wildfire-complex-label',
        'type': 'symbol',
        'source': 'wildfire',
        'layout': {
          'text-field':
            ['get', 'ComplexName']
        },
        'paint': {
          'text-color': '#FF0000',
          'text-opacity': 0.7,
          'text-halo-color': '#FFF',
          'text-halo-width': 1,
          'text-halo-blur': 1
        }
      });

      map.addLayer({
        'id': 'wildfire-complex-symbol',
        'type': 'symbol',
        'source': 'wildfire',
        'layout': {
          'text-field': '🔥',
        },
        'paint': {
          'text-color': '#FF0000',
          'text-opacity': 0.7,
          'text-halo-color': '#FFF',
          'text-halo-width': 1,
          'text-halo-blur': 1
        }
      });

    });
  });


  return (
    <div className="Map">
      <div id="map-container"/>
    </div>
);
}

export default Map;
